import React from "react";
import { useSelector } from "react-redux";

//images
import logoDark from "../../assets/images/branding/logo_dark.png";
import avatar3 from "../../assets/images/avatars/3.png";

//components
import AssesmentComponent from "./";
import CountdownTimer from "../CountdownTImer";
//modals
import SuccessModal from "./modal/SuccessModal";
import ErrorModal from "./modal/ErrorModal";
import ConfirmationModal from "./modal/ConfirmationModal";
import { getInitials } from "../../utils/commonHelper";

const AssesmentWrapper = ({
  currentQuestion,
}) => {
  //misc
  const { sectionTitle, userDetails, isStartTimer } = useSelector(
    (state) => state.authReducer
  );
  const assessmentTitle = useSelector((state) => state.authReducer.assessmentTitle); // Get assessmentTitle from state
  const currentSectionIndex = useSelector((state) => state.authReducer.currentSectionIndex); // Get currentSectionIndex from state
  const { isConfirmationModal, isSuccessModal, isTimerCompletedModal } =
    useSelector((state) => state.modalReducer);

  return (
    <div
      className=" gap-3 assesment_container"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      {/* nav */}
      <div className=" p-3 logo_container">
        <div className="main_section">
          <div className="left_section">
            <span
              className="justify-content-center align-items-center"
              style={{ fontSize: "x-large" }}
            >
              {" "}
              <img src="/assessli_b.png" alt="Logo" className="assessment_logo" />{" "}
              
            </span>
          </div>

          <div className="mid_section">
            <span>
              QP ID <strong>{assessmentTitle}</strong>
            </span>
          </div>
          <div className="right_section" style={{alignItems: 'center', justifyContent: 'end'}}>
            <div className="nav-item navbar-dropdown dropdown-user dropdown">
              <strong
                className="nav-link dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="avatar" style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  borderColor: '#9968E7',
                  borderStyle: 'solid',
                  borderWidth: '1px'
                }}>
                  {/* <img src={avatar3} alt="" className="h-auto rounded-circle" /> */}
                  <label>{getInitials(userDetails?.fullName)}</label>
                </div>
              </strong>
            </div>

            <h6 className="px-2">{userDetails?.fullName}</h6>
          </div>
        </div>
      </div>
      {/* heading */}
      <div className=" p-3 justify-content-center align-items-center timer_section">
        <div className="left_section">
          <span>
            <label>Section {currentSectionIndex + 1}:</label> 
            <strong>{sectionTitle}</strong>
          </span>
        </div>
        <div className="mid_section">
            <span>
              <>QP ID:</>
              <strong>{assessmentTitle}</strong>
            </span>
        </div>
        <div className="right_section">
          <span>
            Total Time left:{" "}
            <strong>
              {isStartTimer ? <CountdownTimer /> : null}
              {/* <CountdownTimer
                initialHours={1}
                bypassLocalStorage={true}
                testSeconds={10}
              /> */}
            </strong>
          </span>
        </div>
      </div>

      {/* <SuccessModal
        title={"Hello title"}
        text={"Hello TExt"}
        onClose={() => {}}
      /> */}
      {/* {isConfirmationModal ? (
        <ConfirmationModal
          title={"Are you sure, you want to submit ?"}
          text={"Hello TExt"}
          onClose={() => {}}
        />
      ) : null} */}

      {/* <ErrorModal
        title={"Hello title Error"}
        text={"Hello TExt"}
        onClose={() => {}}
      /> */}

      {/* questions */}
      <AssesmentComponent
        isConfirmationModal={isConfirmationModal}
        isSuccessModal={isSuccessModal}
        isTimerCompletedModal={isTimerCompletedModal}
        currentQuestion={currentQuestion}
      />
    </div>
  );
};

export default AssesmentWrapper;
